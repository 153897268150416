<template>
  <v-row justify="center">
        <v-col cols="12" class="text-center">
            Fetching Records. Please Wait!
          <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
  </v-row>
</template>

<script>
  export default {
        name: 'InfoLoader2',
        data () {
        return {
            //
        }
    },
  }
</script>